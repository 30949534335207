import React from "react"
import "./image-carousel.scss"
import 'uikit/dist/css/uikit.min.css';

const ImageCarousel = ({ props }) => {

    return (
        <>
            <div style={{ background: `${props.bgColor ? props.bgColor : "#f9f9f9"}` }}>
                <div className="carousel-container">
                    <div className="uk-slider uk-slider-container" uk-slider="true">
                        <div className="uk-position-relative uk-visible-toggle slider-content" tabIndex="-1">

                            {
                                props.title ?
                                    <h3 className={(props.text || props.paragraph) ? "light-title" : "dark-title"}>{props.title}</h3>
                                    : ""
                            }

                            {(props.text || props.paragraph) ?
                                <hr style={{ width: "50px", margin: "0px auto 30px auto" }} />
                                : ""}

                            <div className="uk-slider-container uk-light uk-animation-toggle" uk-scrollspy="cls: uk-animation-slide-bottom-small; target: .slider; delay: 500; repeat: false" tabIndex="0">
                                {
                                    props.images ?
                                        <ul className="slider uk-slider-items uk-child-width-1-4@m uk-child-width-1-2@s uk-child-width-1-1@xs">
                                            {
                                                props.images ? props.images.map((item, index) => {
                                                    return (

                                                        <li key={index} className="image-card">
                                                            <div className="uk-card uk-margin card-item" data-uk-grid>
                                                                <img src={item.image} alt="Slider" className="item-image" />
                                                            </div>
                                                        </li>

                                                    )
                                                })
                                                    : ""
                                            }
                                        </ul>
                                        :
                                        props.text ?
                                            <ul className="uk-slider-items uk-child-width-1-1@m">
                                                {
                                                    props.text ? props.text.map((item, index) => {
                                                        return (

                                                            <li key={index} className="image-card">
                                                                <div className="card-item" >
                                                                    <h3 className="subtitle">{item.subtitle}</h3>
                                                                    <p className="info">{item.info}</p>
                                                                </div>
                                                            </li>

                                                        )
                                                    })
                                                        : ""
                                                }
                                            </ul>
                                            :
                                            props.paragraph ?
                                                <ul className="uk-slider-items uk-child-width-1-1@m">
                                                    {
                                                        props.paragraph.map((item, index) => {
                                                            return (

                                                                <li key={index} className="paragraph-card uk-child-width-1-2@m uk-child-width-1-1@s">
                                                                    <div className="card-image" >
                                                                        <img src={item.image} />
                                                                    </div>
                                                                    <div className="card-info">
                                                                        <h3 className="subtitle">{item.subtitle}</h3>
                                                                        {item.info ? <p className="par-info">{item.info}</p> : ""}
                                                                        {item.url ? <a href={item.url} className="par-button">{item.label}</a> : ""}
                                                                    </div>
                                                                </li>

                                                            )
                                                        })

                                                    }
                                                </ul>
                                                : ""

                                }

                            </div>


                            <a className="uk-position-center-left uk-position-small uk-hidden-hover" id={props.theme == 'light' ? "dark-arrow left" : "light-arrow left"} href="#" uk-slidenav-previous="true" uk-slider-item="previous" aria-label="Slider Left Arrow"></a>
                            <a className="uk-position-center-right uk-position-small uk-hidden-hover" id={props.theme == 'light' ? "dark-arrow right" : "light-arrow right"} href="#" uk-slidenav-next="true" uk-slider-item="next" aria-label="Slider Right Arrow"></a>

                        </div>

                        <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin" id={props.theme == 'light' ? "dark-element" : "light-element"}></ul>
                    </div>
                </div>
            </div >
        </>
    )
}

export default ImageCarousel